import React from 'react';

const DashboardMain = () => {
    return (
        <div style={{marginTop:'1000px'}}>
            <h1>Hi From main</h1>
        </div>
    );
};

export default DashboardMain;