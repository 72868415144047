import React from 'react';

const StudentsHeader = () => {
    return (
        <div>
            <div
        style={{backgroundColor:'blue' , height:'50vh', display:'flex' , justifyContent:'center' , alignItems:'center' , flexDirection:'column'}}>
            <h1 style={{margin:'0' , color:'white'}}>Meet The Students</h1>
            <h1 style={{margin:'0', color:'white'}}>Our Prides</h1>
        </div>
        </div>
    );
};

export default StudentsHeader;