import React from 'react';
import Navbar from '../../Shared/Navbar/Navbar';
import '../Gallary/G.css'
const ContactHeader = () => {
    return (
        <div>
            
            <div className='h'>
      
      <div className="overlay">
            <h1>Contact Us</h1>
            


		</div>
       
        </div>
        </div>
    );
};

export default ContactHeader;