import React from 'react';

import './G.css'
const GallaryHeader = () => {
    return (
        <div className='h' >
      
      <div className="overlay">
<h1>Our Gallary</h1>


	
		</div>
       
        </div>
    );
};

export default GallaryHeader;