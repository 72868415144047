import React from 'react';
import headSpeech from '../../assets/images/head.png'
const Header = ({speeches}) => {
    return (
        <div>
            <img src={headSpeech} alt="" srcset="" style={{width:'100%' , height:'300px'}} />
        </div>
    );
};

export default Header;