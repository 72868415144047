import React from 'react';
import nurse from '../../../assets/images/About Banner.jpg'
import Welcome from '../../Home/Welcome/Welcome';
import './AboutUs.css'
const About = () => {
    return (
        <div>
            <div>
            <div className='h'  >
      
      <div className="overlay">
            <h1>About Us</h1>
                

	<br/>

		</div>
       
        </div>
            </div>
            <div >
                <Welcome></Welcome>
            </div>
        </div>
    );
};

export default About;